import React, {useState} from 'react';
import ReactQuill from "react-quill";
import './Rent.css'
import "react-quill/dist/quill.snow.css";
import bg from './_2.png'

import crea from './creation.jpeg'
import p2 from './panier_2.jpeg'
import p3 from './panier_3.jpeg'
import marche from './marche.jpeg'

import logo from './logo.svg';
import {Link} from "react-router-dom";
import crea1 from "./crea_1.jpeg";

function Rent() {


    return (
        <div className="Rent">
               <div className="TitleCreation">
               De la décoration de mariage sera prochainement proposée à la location.
            </div>
            {/*<div className="RentContainer">
                <div className="RentTitle">
                    <div>
                        <p>"Ensemble n°1"</p>
                        <p> Mariage / Pacs </p>
                    </div>
                    <div>
                    </div>
                </div>
                <img className="RentImage" style={{flex: 1}} src={crea1}/>

                <div className="RentText" style={{flex: 1}}>
                    <p>Composition</p>
                    <p>de 35 € à 90 €</p>
                </div>
            </div>   <div className="RentContainer">
                <div className="RentTitle">
                    <div>
                        <p>"Ensemble n°1"</p>
                        <p> Mariage / Pacs </p>
                    </div>
                    <div>
                    </div>
                </div>
                <img className="RentImage" style={{flex: 1}} src={crea1}/>

                <div className="RentText" style={{flex: 1}}>
                    <p>Composition</p>
                    <p>de 35 € à 90 €</p>
                </div>
            </div>*/}


        </div>
    );
}

export default Rent;
